import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';
import Pagination from '@mui/material/Pagination';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import '../Table.css';
import 'ka-table/style.css';

import { Header, SearchBar, CustomLoadingIndicator, CustomModal, CustomTooltipComponent, SubHeader } from '../../components';
import { MdAdd, MdList, MdRemove } from 'react-icons/md';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';

import { updateData } from 'ka-table/actionCreators';
import { kaReducer, Table } from 'ka-table';
import { DataType, EditingMode, SortingMode } from 'ka-table/enums';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

import { formatDate, themeColorsUsable } from '../../data/buildData';

const PreWarmUpCustomers = () => {

  // Default
  const { currentColor, loggedIn, loadingIndicatorActive, setLoadingIndicatorActive } = useStateContext();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();


  // Main Data
  const [customersArray, setCustomersArray] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");

  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState("");

  const [companyName, setCompanyName] = useState("");
  const [companyNameError, setCompanyNameError] = useState("");

  // Table pagination
  const [page, setPage] = useState(1); // Current page
  const [totalPages, setTotalPages] = useState(0); // Total number of pages
  const [totalRows, setTotalRows] = useState(0); // Total number of rows
  const [limit, setLimit] = useState(10); // Items per page

  // Table Sort
  const [sortField, setSortField] = useState("_id");
  const [sortOrder, setSortOrder] = useState("desc"); // 'asc' or 'desc'

  const handleSort = (field) => {
    let newOrder = 'asc';
    if (sortField === field && sortOrder === 'asc') {
      newOrder = 'desc';
    }
    setSortField(field);
    setSortOrder(newOrder);
    getCustomers(page, limit, field, newOrder);
  };

  // Table bulk selection
  const [selectedRows, setSelectedRows] = useState(new Set());
  const onSelectionChange = (rowKeyValue) => {
    setSelectedRows(prev => {
      const newSet = new Set(prev);
      if (newSet.has(rowKeyValue)) {
        newSet.delete(rowKeyValue);
      } else {
        newSet.add(rowKeyValue);
      }
      return newSet;
    });
  };

  const onSelectAll = () => {
    if (selectedRows.size === customersArray.length) {
      setSelectedRows(new Set());
    } else {
      setSelectedRows(new Set(customersArray.map(item => item._id)));
    }
  };

  // START Add New Item
  const [openAddCustomerModal, setOpenAddCustomerModal] = useState(false);

  const resetAddCustomerModal = () => {
    setFirstName("");
    setFirstNameError("");

    setLastName("");
    setLastNameError("");

    setCompanyName("");
    setCompanyNameError("");
  }

  const handleOpenAddCustomerModal = () => {
    setOpenAddCustomerModal(true);
  }

  const handleCloseAddCustomerModal = () => {
    setOpenAddCustomerModal(false);
    resetAddCustomerModal();
  }
  const isValidAddCustomer = () => {
    var isValid = true;
    // Trim to remove any leading/trailing whitespace
    if (firstName.trim().length === 0) {
      setFirstNameError("First name is required.");
      isValid = false;
    }
    if (lastName.trim().length === 0) {
      setLastNameError("Last name is required.");
      isValid = false;
    }
    if (companyName.trim().length === 0) {
      setCompanyNameError("Company name is required.");
      isValid = false;
    }

    if (!isValid) {
      return false;
    }
    // Reset error message if validation passes
    setFirstNameError("");
    setLastNameError("");
    setCompanyNameError("");
    
    return true;
  };
  const addCustomer = async () => {
    setLoadingIndicatorActive(true);

    if (!isValidAddCustomer()) {
      setLoadingIndicatorActive(false);
      return;
    }

    try {
      const response = await axiosPrivate.post(`/api/v1/customer/add-one`, {
        firstName,
        lastName,
        companyName
      },
        { headers: { 'Content-Type': 'application/json' } });
      if (response.data.success) {
        console.log("success");
        handleCloseAddCustomerModal();
        getCustomers(page);
      } else {
        console.log("Failure");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingIndicatorActive(false);
    }
  }
  // END Add New Item

  // START Delete Item
  const [customerIdToDelete, setCustomerIdToDelete] = useState("");
  const [openDeleteCustomerModal, setOpenDeleteCustomerModal] = useState(false);

  const handleOpenDeleteCustomerModal = (customerId) => {
    setCustomerIdToDelete(customerId)
    setOpenDeleteCustomerModal(true);
  }

  const handleCloseDeleteCustomerModal = () => {
    setCustomerIdToDelete("");
    setOpenDeleteCustomerModal(false);
  }

  const deleteCustomer = async () => {
    setLoadingIndicatorActive(true);
    if (customerIdToDelete.length === 0) {
      handleCloseDeleteCustomerModal();
    }
    try {
      const response = await axiosPrivate.post(`/api/v1/customer/delete-one`, {
        customerIdToDelete,
      },
        { headers: { 'Content-Type': 'application/json' } });
      if (response.data.success) {
        console.log("success");
        handleCloseDeleteCustomerModal();
        getCustomers(page);
      } else {
        console.log("Failure");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingIndicatorActive(false);
    }
  }

  // END Delete Item

  // START Edit Item
  const [customerIdToEdit, setCustomerIdToEdit] = useState("");
  const [openEditCustomerModal, setOpenEditCustomerModal] = useState(false);

  const resetEditCustomerModal = () => {
    setCustomerIdToEdit("");
    setFirstName("");
    setFirstNameError("");
    setLastName("");
    setLastNameError("");
    setCompanyName("");
    setCompanyNameError("");
  }

  const handleOpenEditCustomerModal = (customerId) => {
    setCustomerIdToEdit(customerId);
    for (var i = 0; i < customersArray.length; i++) {
      if (customersArray[i]._id === customerId) {
        setFirstName(customersArray[i].firstName);
        setLastName(customersArray[i].lastName);
        setCompanyName(customersArray[i].companyName);
        break;
      }
    }
    setOpenEditCustomerModal(true);
  }

  const handleCloseEditCustomerModal = () => {
    setOpenEditCustomerModal(false);
    resetEditCustomerModal();
  }

  const isValidEditCustomer = () => {
    var isValid = true;

    // Trim to remove any leading/trailing whitespace
    if (customerIdToEdit.trim().length === 0) {
      isValid = false;
    }
    if (firstName.trim().length === 0) {
      setFirstNameError("First name is required.");
      isValid = false;
    }
    if (lastName.trim().length === 0) {
      setLastNameError("Last name is required.");
      isValid = false;
    }
    if (companyName.trim().length === 0) {
      setCompanyNameError("Company name is required.");
      isValid = false;
    }

    if (!isValid) {
      return false;
    }
    // Reset error message if validation passes
    setFirstNameError("");
    setLastNameError("");
    setCompanyNameError("");
    
    return true;
  };

  const editCustomer = async (objectId) => {
    setLoadingIndicatorActive(true);

    if (!isValidEditCustomer()) {

      setLoadingIndicatorActive(false);
      // show error message for first name field
      return;
    }

    try {
      const response = await axiosPrivate.post(`/api/v1/customer/edit-one`, {
        customerIdToEdit,
        firstName,
        lastName,
        companyName
      },
        { headers: { 'Content-Type': 'application/json' } });
      if (response.data.success) {
        console.log("success");
        handleCloseEditCustomerModal();
        getCustomers(page);
      } else {
        console.log("Failure");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingIndicatorActive(false);
    }
  }
  // END Edit Item


  // START Table Configuration
  const ROW_MOUSE_ENTER = 'ROW_MOUSE_ENTER';
  const ROW_MOUSE_LEAVE = 'ROW_MOUSE_LEAVE';

  const tablePropsInit = {
    columns: [
      // { key: 'selection-column', title: '', dataType: DataType.Boolean, width: 50, style: { textAlign: 'center' }, headerStyle: { textAlign: 'center' } },
      { key: '_id', title: 'ID', dataType: DataType.String, colGroup: { style: { minWidth: 200 } }, width: 200, },
      { key: 'firstName', title: 'First Name', dataType: DataType.String, colGroup: { style: { minWidth: 200 } }, width: 200 },
      { key: 'lastName', title: 'Last Name', dataType: DataType.String, colGroup: { style: { minWidth: 200 } }, width: 200 },
      { key: 'companyName', title: 'Company Name', dataType: DataType.String, colGroup: { style: { minWidth: 200 } }, width: 200 },
      { key: 'numberOfDomains', title: 'Domains', dataType: DataType.String, colGroup: { style: { minWidth: 200 } }, width: 200 },
      { key: 'dateAdded', title: 'Date Added', dataType: DataType.String, colGroup: { style: { minWidth: 150 } }, width: 150 },
      { key: 'Actions', title: 'Actions', dataType: DataType.Object, colGroup: { style: { minWidth: 200 } }, width: 200, },
    ],
    data: customersArray,
    editingMode: EditingMode.None,
    rowKeyField: '_id',
    sortingMode: SortingMode.SingleRemote,
  };
  const [tableProps, changeTableProps] = useState(tablePropsInit);
  const dispatch = (action) => {
    changeTableProps((prevState) => kaReducer(prevState, action));
  };

  // END Table Configuration

  // START Get Main Page Data
  const getCustomers = async (newPage = page, newLimit = limit, newSortField = sortField, newSortOrder = sortOrder, newSearchTerm = searchTerm) => {
    setLoadingIndicatorActive(true);

    try {
      const skip = (newPage - 1) * newLimit;
      const response = await axiosPrivate.post(`/api/v1/customer/get`, {
        limit: newLimit,
        skip,
        sortField: newSortField,
        sortOrder: newSortOrder,
        searchTerm: newSearchTerm
      }, { headers: { 'Content-Type': 'application/json' } });

      if (response.data.success) {
        setCustomersArray(response.data.data);
        setTotalRows(response.data.total);
        const newTotalPages = Math.ceil(response.data.total / newLimit);
        setTotalPages(newTotalPages);
        if (newPage > newTotalPages) {
          setPage(newTotalPages || 1);
        } else {
          setPage(newPage);
        }
        dispatch(updateData(response.data.data));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingIndicatorActive(false);
    }
  };

  useEffect(() => {
    if (loggedIn) {
      getCustomers(page, limit, sortField, sortOrder);
    } else {
      navigate("/login");
    }
  }, [page, limit, sortField, sortOrder, loggedIn]);
  // END Get Main Page Data

  // START Setup Modal Views Data
  const elementsAddCustomerModal = [
    {
      type: 'title',
      props: {
        label: "Add New Customer",
      }
    },
    {
      type: 'description',
      props: {
        label: "Enter the First Name, Last Name and Company Name.",
      }
    },
    {
      type: 'inputField',
      props: {
        label: "First Name",
        value: firstName,
        required: true,
        error: !!firstNameError, // Changed to boolean for error prop
        helperText: firstNameError,
        onChange: (e) => {
          setFirstName(e.target.value);
          if (firstNameError) setFirstNameError(""); // Reset error when user starts typing
        },
      }
    },
    {
      type: 'inputField',
      props: {
        label: "Last Name",
        value: lastName,
        required: true,
        error: !!lastNameError, // Changed to boolean for error prop
        helperText: lastNameError,
        onChange: (e) => {
          setLastName(e.target.value);
          if (lastNameError) setLastNameError(""); // Reset error when user starts typing
        },
      }
    },
    {
      type: 'inputField',
      props: {
        label: "Company Name",
        value: companyName,
        required: true,
        error: !!companyNameError, // Changed to boolean for error prop
        helperText: companyNameError,
        onChange: (e) => {
          setCompanyName(e.target.value);
          if (companyNameError) setCompanyNameError(""); // Reset error when user starts typing
        },
      }
    },
    
  ];


  const elementsEditCustomerModal = [
    {
      type: 'title',
      props: {
        label: "Edit Customer",
      }
    },
    {
      type: 'description',
      props: {
        label: "Enter the First Name, Last Name and Company Name.",
      }
    },
    {
      type: 'inputField',
      props: {
        label: "First Name",
        value: firstName,
        required: true,
        error: !!firstNameError, // Changed to boolean for error prop
        helperText: firstNameError,
        onChange: (e) => {
          setFirstName(e.target.value);
          if (firstNameError) setFirstNameError(""); // Reset error when user starts typing
        },
      }
    },
    {
      type: 'inputField',
      props: {
        label: "Last Name",
        value: lastName,
        required: true,
        error: !!lastNameError, // Changed to boolean for error prop
        helperText: lastNameError,
        onChange: (e) => {
          setLastName(e.target.value);
          if (lastNameError) setLastNameError(""); // Reset error when user starts typing
        },
      }
    },
    {
      type: 'inputField',
      props: {
        label: "Company Name",
        value: companyName,
        required: true,
        error: !!companyNameError, // Changed to boolean for error prop
        helperText: companyNameError,
        onChange: (e) => {
          setCompanyName(e.target.value);
          if (companyNameError) setCompanyNameError(""); // Reset error when user starts typing
        },
      }
    },
    
  ];

  const elementsDeleteCustomerModal = [
    {
      type: 'title',
      props: {
        label: "Delete Customer",
      }
    },
    {
      type: 'description',
      props: {
        label: "Are you sure you wish to delete this Customer? This action cannot be undone!",
      }
    },
  ];

  // END Setup Modal Views Data


  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl dark:text-gray-200 dark:bg-secondary-dark-bg">
      <Header category="Pre-Warm Up" title="Customers" />

      <SearchBar
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        onSearchClick={() => getCustomers(page, limit, sortField, sortOrder, searchTerm)}
        currentColor={currentColor}
      />
      <SubHeader title={`Total rows: ${totalRows}`} />
      <CustomLoadingIndicator isActive={loadingIndicatorActive} />

      <CustomModal
        open={openAddCustomerModal}
        handleClose={handleCloseAddCustomerModal}
        elements={elementsAddCustomerModal}
        confirmFunction={addCustomer}
      />

      <CustomModal
        open={openEditCustomerModal}
        handleClose={handleCloseEditCustomerModal}
        elements={elementsEditCustomerModal}
        confirmFunction={editCustomer}
      />

      <CustomModal
        open={openDeleteCustomerModal}
        handleClose={handleCloseDeleteCustomerModal}
        elements={elementsDeleteCustomerModal}
        confirmFunction={deleteCustomer}
      />

      <div className='flex justify-end mb-10 -mt-24 mr-10 md:mr-20'>
        <CustomTooltipComponent
          icon={MdAdd}
          tooltipText="Add New Customer"
          onClick={handleOpenAddCustomerModal}
          currentColor={currentColor}
        />
      </div>
      <div style={{ overflowX: 'auto' }}>

        <Table
          {...tableProps}
          dispatch={dispatch}
          childComponents={{
            noDataRow: {
              content: () => 'No Customers Found'
            },
            headCell: {
              content: props => {
                if (props.column.key === 'selection-column') {
                  return (
                    <input
                      type="checkbox"
                      checked={selectedRows.size === customersArray.length}
                      onChange={onSelectAll}
                    />
                  );
                } else {
                  return (
                    <div onClick={() => handleSort(props.column.key)}>
                      {props.column.title} {props.column.key == sortField ? sortOrder == "asc" ? "↑" : "↓" : ""}
                    </div>
                  );
                }
              }
            },

            dataRow: {
              elementAttributes: (props) => ({
                onMouseEnter: (event, extendedEvent) => {
                  const {
                    childProps: {
                      rowKeyValue,
                    },
                    dispatch,
                  } = extendedEvent;
                  dispatch({ type: ROW_MOUSE_ENTER, rowKeyValue });
                },
                onMouseLeave: (event, { dispatch }) => {
                  dispatch({ type: ROW_MOUSE_LEAVE });
                }
              })
            },
            cell: {
              content: props => {
                if (props.column.key === 'selection-column') {
                  return <input
                    type="checkbox"
                    checked={selectedRows.has(props.rowData._id)}
                    onChange={() => onSelectionChange(props.rowData._id)}
                  />;
                }
                // ... other cell contents
              },
              elementAttributes: () => ({
                // className: 'my-cell-class',
                onClick: (e, extendedEvent) => {
                  // const { childProps: { dispatch } } = extendedEvent;
                  // dispatch({ type: 'MY_CELL_onClick', ...{ extendedEvent }});
                  if (extendedEvent.childProps.column.key !== "Actions") {
                    // console.log("clicked row " + extendedEvent.childProps.rowKeyValue)
                    // navigateToClientDetailsWithID(extendedEvent.childProps.rowKeyValue);
                  }
                },
              })
            },
            cellText: {
              content: (props) => {
                switch (props.column.key) {
                  case 'dateAdded': return (
                    formatDate(props.rowData.dateAdded)
                  );
                  case 'Actions': return (
                    <div className='flex'>
                      <CustomTooltipComponent
                        icon={AiFillEdit}
                        tooltipText="Edit Customer"
                        onClick={() => handleOpenEditCustomerModal(props.rowData._id)}
                        currentColor={currentColor}
                      />
                      &nbsp;
                      <CustomTooltipComponent
                        icon={AiFillDelete}
                        tooltipText="Delete Customer"
                        onClick={() => handleOpenDeleteCustomerModal(props.rowData._id)}
                        currentColor={themeColorsUsable.red}
                      />
                    </div>
                  );
                }
              }
            },

          }}
        />
      </div>

      <div className="flex justify-between items-center my-4">
        <Select
          value={limit}
          onChange={(e) => {
            setLimit(e.target.value);
            getCustomers(1, e.target.value);
            setPage(1); // Reset to first page
          }}
        >
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={20}>20</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
        </Select>
        <Pagination
          count={totalPages}
          page={page}
          onChange={(event, newPage) => getCustomers(newPage)}
        />
      </div>
    </div>
  );
};

export default PreWarmUpCustomers;