import React from 'react';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import './CustomTooltip.css'; // Import the custom CSS

const CustomTooltipComponent = ({ icon: Icon, tooltipText, onClick, currentColor, disabled }) => {
    return (
        <TooltipComponent 
            content={tooltipText} 
            position="BottomCenter" 
            cssClass="custom-tooltip"
            width="200px" // Ensure the width is set to auto
            >
            <button
                type="button"
                onClick={onClick}
                disabled={disabled}
                style={{ backgroundColor: currentColor, color: "white", borderRadius: "50%" }}
                className="text-lg p-3 hover:drop-shadow-xl hover:bg-light-gray"

            >
                <Icon />
            </button>
        </TooltipComponent>
    );
};

export default CustomTooltipComponent;
